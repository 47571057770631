.landingWrapper {
	text-align: center;
	background-color: rgba(1, 11, 51, 0.5);
}
.paint {
	position: relative;
	z-index: -1;
	width: 100%;
}
.title {
	font-family: 'Oswald', sans-serif;
	font-size: 350px;
	font-weight: 400;
	margin: 0;
	margin-top: -52%;
	padding: 0;
	color: rgba(0, 0, 0, 0);
	-webkit-text-stroke-width: 4px;
	-webkit-text-stroke-color: white;
}
.skillTitles {
	font-family: 'Source Sans Pro', sans-serif;
	font-size: 60px;
	font-weight: 200;
	color: white;
}
.bounce {
	margin-top: 7%;
	animation: bounce 2s infinite;
}
.downArrow {
	display: block;
	margin: auto;
	width: 40px;
	padding-bottom: 140px;
}

@keyframes bounce {
	0%,
	20%,
	50%,
	80%,
	100% {
		-moz-transform: translateY(0);
		-ms-transform: translateY(0);
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	40% {
		-moz-transform: translateY(-30px);
		-ms-transform: translateY(-30px);
		-webkit-transform: translateY(-30px);
		transform: translateY(-30px);
	}
	60% {
		-moz-transform: translateY(-15px);
		-ms-transform: translateY(-15px);
		-webkit-transform: translateY(-15px);
		transform: translateY(-15px);
	}
}

/* Media Queries */
@media screen and (max-width: 1600px) {
	.title {
		margin-top: -52%;
	}
	.downArrow {
		padding-bottom: 110px;
	}
}
@media screen and (max-width: 1500px) {
	.title {
		margin-top: -56%;
	}
	.bounce {
		margin-top: 6%;
	}
}
@media screen and (max-width: 1400px) {
	.title {
		margin-top: -58%;
	}
	.bounce {
		margin-top: 7%;
	}
}
@media screen and (max-width: 1350px) {
	.title {
		margin-top: -60%;
	}
}
@media screen and (max-width: 1300px) {
	.title {
		margin-top: -58%;
		font-size: 300px;
	}
	.bounce {
		margin-top: 8%;
	}
}
@media screen and (max-width: 1200px) {
	.title {
		margin-top: -60%;
	}
}
@media screen and (max-width: 1150px) {
	.title {
		font-size: 260px;
		margin-top: -58%;
	}
}
@media screen and (max-width: 1100px) {
	.title {
		font-size: 240px;
		margin-top: -56%;
	}
	.skillTitles {
		font-size: 40px;
	}
	.bounce {
		margin-top: 7%;
	}
}
@media screen and (max-width: 1050px) {
	.title {
		margin-top: -58%;
	}
}
@media screen and (max-width: 1000px) {
	.skillTitles {
		font-size: 35px;
	}
	.title {
		font-size: 220px;
		margin-top: -56%;
	}
	.downArrow {
		width: 30px;
	}
}
@media screen and (max-width: 950px) {
	.downArrow {
		padding-bottom: 80px;
	}
}
@media screen and (max-width: 900px) {
	.downArrow {
		padding-bottom: 60px;
	}
}
@media screen and (max-width: 850px) {
	.title {
		font-size: 220px;
		-webkit-text-stroke-width: 2px;
		margin-top: -60%;
	}
}
@media screen and (max-width: 770px) {
	.title {
		font-size: 200px;
	}
	.skillTitles {
		font-size: 32px;
	}
	.downArrow {
		width: 30px;
	}
}
@media screen and (max-width: 715px) {
	.title {
		font-size: 180px;
	}
}
@media screen and (max-width: 680px) {
	.title {
		font-size: 160px;
		margin-top: -58%;
	}
	.downArrow {
		padding-bottom: 50px;
	}
}
@media screen and (max-width: 610px) {
	.title {
		font-size: 140px;
	}
	.skillTitles {
		font-size: 28px;
	}
}
@media screen and (max-width: 530px) {
	.title {
		font-size: 120px;
	}
	.downArrow {
		width: 20px;
	}
}
@media screen and (max-width: 480px) {
	.title {
		font-size: 110px;
		-webkit-text-stroke-width: 1px;
	}
	.downArrow {
		width: 20px;
	}
	.skillTitles {
		font-size: 24px;
	}
}
@media screen and (max-width: 450px) {
	.title {
		font-size: 100px;
	}
}
@media screen and (max-width: 420px) {
	.title {
		font-size: 90px;
	}
	.skillTitles {
		font-size: 18px;
	}
	.bounce {
		margin-top: 9%;
	}
	.downArrow {
		width: 15px;
	}
}
@media screen and (max-width: 380px) {
	.downArrow {
		padding-bottom: 20px;
	}
}
@media screen and (max-width: 360px) {
	.downArrow {
		padding-bottom: 10px;
	}
}
