* {
	margin: 0;
	padding: 0;
}
.aboutWrapper {
	width: 60%;
	display: block;
	margin: auto;
	margin-top: 10%;
	border: 2px solid white;
	padding: 3%;
}
.aboutTitle {
	font-family: 'Oswald', sans-serif;
	font-size: 150px;
	font-weight: 400;
	margin: 0;
	margin-top: -5%;
	color: rgba(0, 0, 0, 0);
	-webkit-text-stroke-width: 2px;
	-webkit-text-stroke-color: #00605c;
}
.aboutText {
	font-family: 'Source Sans Pro', sans-serif;
	font-size: 24px;
	color: white;
	line-height: 34px;
}
.iconWrapper {
	width: 30%;
	display: flex;
	margin-top: 4%;
}
.aboutIcons {
	width: 50px;
	margin-right: 20px;
}

/* Media Queries  */
@media screen and (max-width: 1100px) {
	.aboutWrapper {
		width: 75%;
		margin-top: 6%;
	}
}
@media screen and (max-width: 900px) {
	.aboutWrapper {
		margin-top: 4%;
	}
}
@media screen and (max-width: 770px) {
	.aboutTitle {
		font-size: 130px;
	}
	.aboutText {
		font-size: 20px;
		line-height: 30px;
	}
	.aboutWrapper {
		margin-top: 10%;
	}
}
@media screen and (max-width: 600px) {
	.aboutTitle {
		font-size: 110px;
	}
	.iconWrapper {
		margin-top: 6%;
	}
	.aboutWrapper {
		margin-top: 6%;
	}
}
@media screen and (max-width: 420px) {
	.aboutTitle {
		font-size: 60px;
		-webkit-text-stroke-width: 1px;
	}
	.aboutText {
		font-size: 16px;
		line-height: 28px;
	}
	.iconWrapper {
		margin-top: 8%;
	}
	.aboutWrapper {
		padding: 6%;
		border: 1px solid white;
		margin-top: 8%;
	}
}
@media screen and (max-width: 410px) {
	.aboutWrapper {
		margin-top: 4%;
	}
}
@media screen and (max-width: 380px) {
	.aboutWrapper {
		margin-top: 6%;
	}
}
@media screen and (max-width: 360px) {
	.aboutWrapper {
		margin-top: 8%;
	}
}
