.footerWrapper {
    width: 85%;
    display: flex;
    justify-content: space-between;
    margin: auto;
    border-top: 2px solid #00605c;
    padding-bottom: 2%;
}
.copyRight{
    margin-top: 3%;
    font-family: 'Source Sans Pro', sans-serif;
    color: white;
    font-size: 20px;
}
.footerIconWrapper {
    display: flex;
    margin-top: 2%;
}
.footerIcons {
    width: 40px;
    margin-left: 20px;
    float: right;
    color: white;
}

/* Media Queries  */
@media screen and (max-width: 700px){
    .copyRight{
        font-size: 18px;
    }
}
@media screen and (max-width: 600px){
    .copyRight{
        font-size: 16px;
    }
}
@media screen and (max-width: 500px){
    .copyRight{
        font-size: 14px;
    }
    .footerWrapper {
        padding-bottom: 4%;
    }
    .footerIcons {
        margin-left: 10px;
    }
}
@media screen and (max-width: 450px){
    .copyRight{
        font-size: 12px;
        margin-top: 5%;
    }
    .footerIcons {
        width: 30px;
    }
    .footerIconWrapper {
        margin-top: 3%;
    }
}
@media screen and (max-width: 380px){
    .copyRight{
        margin-top: 4%;
    }
    .footerIconWrapper {
        margin-top: 4%;
    }
}