.projectWrapper{
    width: 85%;
    display: block;
    margin: auto;
    margin-top: 8%;
    margin-bottom: 8%;
}
.project {
    font-family: 'Oswald', sans-serif;
    font-weight: 400;
    font-size: 150px;
    color: rgba(0,0,0,0);
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: white;
    margin-left: -10px;
}
.role {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 48px;
    color: #00605c;
    margin-top: -1%;
    margin-bottom: 4%;
}
.description {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 24px;
    color: white;
    line-height: 40px;
    margin-bottom: 2%;
}
.backTop, .backBottom {
    font-family: 'Oswald', sans-serif;
    color: white;
    font-size: 24px;
    background-color: rgba(0,0,0,0);
    border: 0px;
    cursor: pointer;
    transition: 1s ease;
}
.backBottom {
    margin-top: 6%;
}
.backTop:hover, .backBottom:hover {
    color: #00605c;
}
.link {
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 24px;
    color: #00605c;
    text-decoration: none;
    transition: 1s ease;
}
.link:hover {
    color: white;
}
.southwest img, .tigerCenter img, .gigReporter img {
    width: 70%;
    display: block;
    margin: auto;
    margin-top: 4%;
}
.priorityThinking img, .phlote img, .connect img, .brandGuide img {
    width: 50%;
    display: block;
    margin: auto;
    margin-top: 4%;
}
.imageWrapper {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 5%;
    margin-top: 5%;
}
.imageWrapper .image1 {
    width: 50%;
}
.imageWrapper .image2 {
    width: 30%;
}

/* MEDIA QUERIES */
@media screen and (max-width: 800px){
    .project {
        font-size: 100px;
        margin-left: 0px;
    }
    .role {
        font-size: 32px;
        margin-top: -2%;
        margin-bottom: 2%;
    }
    .description {
        font-size: 20px;
        line-height: 32px;
    }
    .backTop, .backBottom {
        font-size: 20px;
    }
    .link {
        font-size: 20px;
    }
    .imageWrapper {
        width: 100%;
        display: block;
    }
    .imageWrapper .image1 {
        width: 100%;
        margin-bottom: 8%;
    }
    .imageWrapper .image2 {
        width: 100%;
        margin-bottom: 4%;
    }
}
@media screen and (max-width: 450px){
    .project {
        font-size: 50px;
        -webkit-text-stroke-width: 1px;
        margin-top: 8%;
    }
    .role {
        font-size: 24px;
        margin-top: 0%;
        margin-bottom: 4%;
    }
    .description{
        font-size: 16px;
        line-height: 28px;
    }
    .link {
        font-size: 16px;
    }
    ul {
        margin-top: 8%;
    }
    li {
        margin-bottom: 4%;
    }
    .link {
        color: #039993;
    }
    .imageWrapper .image1 {
        margin-top: 4%;
        margin-bottom: 10%;
    }
    .projectWrapper{
        margin-top: 12%;
    }
    .southwest img, .tigerCenter img, .gigReporter img, .priorityThinking img, .phlote img, .connect img, .brandGuide img {
        width: 100%;
        margin-top: 10%;
        margin-bottom: 10%;
    }
}